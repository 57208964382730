<template>
  <div>
    <v-form @submit.prevent="onLocalSubmit" ref="form" dense>
      <!-- <pre>{{currentItem}}</pre> -->
      <v-row class="mt-2" dense>
        <v-col>
          <h2 class="blod_color mt-2" dense>Dados Gerais: </h2>
          <p></p>
          <v-row dense>
            <v-col class="main-fields">
              <v-text-field dense outlined v-model="currentItem.nome_fantasia" clearable :rules="requiredField">
                <template v-slot:label>
                  <span>Nome fantasia <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
              <v-text-field dense outlined v-model="currentItem.razao_social" clearable :rules="requiredField">
                <template v-slot:label>
                  <span>Razão social <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
             <v-text-field dense outlined v-model="currentItem.cnpj" v-mask="'##.###.###/####-##'"  :rules="requiredField" :error-messages="isCNPJValid === false ? ['CNPJ inválido'] : []" @input="validateCNPJ">
                <template v-slot:label>
                  <span>CNPJ <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
              <v-text-field dense outlined v-model="currentItem.tel" label="Telefone Prinpal" v-mask="'## ##### - ####'" clearable  />
              <v-text-field dense outlined v-model="currentItem.email" label="E-mail Prinpal"  clearable />
              <v-text-field dense outlined v-model="currentItem.site" label="Site" />
              <v-autocomplete dense outlined v-model="currentItem.tipos" :items="tipos"
                item-value="id" item-text="nome" multiple return-object clearable :rules="requiredField">
                 <template v-slot:label>
                  <span>Tipo de Fornecedor<span style="color: red;">*</span></span>
                 </template>
              </v-autocomplete>
              <v-select dense outlined v-model="currentItem.criticidade" clearable :rules="requiredField" :items="['Sim', 'Não']">
                 <template v-slot:label>
                  <span>Criticidade<span style="color: red;">*</span></span>
                 </template>
              </v-select>
            <v-text-field dense outlined v-model="currentItem.data_contrato" label="Data do Contrato / Reajuste"  clearable  type="date"/>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-textarea v-model="currentItem.obs" label="Observação" outlined clearable rows="3" />
            </v-col>
          </v-row>
          <h2 class="blod_color">Endereço: </h2>
          <p></p>
          <v-row dense>
            <v-col class="main-fields">
              <Input label="CEP" @change="autofillAddressFromCep" v-mask="'#####-###'" v-model="currentItem.cep"
                clearable />
              <Input label="Logradouro" v-model="currentItem.logradouro" clearable/>
              <Input label="Número" v-model="currentItem.numero" clearable />
              <v-autocomplete dense label="Estado" v-model="currentItem.estado" outlined :loading="isLoading('get:ufs')"
                :items="ufs" item-value="sigla" item-text="sigla" return-object @change="getLocales" clearable />
              <v-autocomplete dense label="Município" v-model="currentItem.municipio" outlined
                :loading="isLoading('get:locales')" :items="locales" item-value="localidade" item-text="localidade"
                return-object clearable />
              <Input label="Bairro" v-model="currentItem.bairro" clearable />
              <Input label="Complemento" v-model="currentItem.complemento" clearable />
            </v-col>
          </v-row>
          <h2 class="blod_color">Contatos: </h2>
          <p></p>
          <v-row dense>
            <v-col cols="3"> <v-text-field dense outlined v-model="currentItem.contato1" label="Contato" clearable /> </v-col>
            <v-col cols="3"> <v-text-field dense outlined v-model="currentItem.tel1" label="Telefone" placeholder="Digite (XX) XXXX-XXXX" v-mask="'## ##### - ####'" clearable /> </v-col>
            <v-col cols="6"> <v-text-field dense outlined v-model="currentItem.email1" label="E-mail" clearable />  </v-col>
          </v-row>
          <v-row dense>  <v-col cols="3"> <v-text-field dense outlined v-model="currentItem.contato2" label="Contato" clearable />  </v-col>
            <v-col cols="3"> <v-text-field dense outlined v-model="currentItem.tel2" label="Telefone" placeholder="Digite (XX) XXXX-XXXX" v-mask="'## ##### - ####'" clearable /> </v-col>
            <v-col cols="6"> <v-text-field dense outlined v-model="currentItem.email2" label="E-mail" clearable /> </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="3"> <v-text-field dense outlined v-model="currentItem.contato3" label="Contato" clearable /> </v-col>
            <v-col cols="3"> <v-text-field dense outlined v-model="currentItem.tel3" label="Telefone" placeholder="Digite (XX) XXXX-XXXX" v-mask="'## ##### - ####'" clearable /> </v-col>
            <v-col cols="6"> <v-text-field dense outlined v-model="currentItem.email3" label="E-mail" clearable /> </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col>
          <v-btn-toggle v-model="currentItem.ativo" mandatory class="white--text">
            <v-btn :color="!currentItem.ativo ? 'error' : ''">Inativo</v-btn>
            <v-btn :color="currentItem.ativo ? 'green' : ''">Ativo</v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col class="text-end">
          <v-tooltip top :disabled="isFormValid2">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <ConfirmButton :color="isFormValid2 ? 'success' : 'grey'" :loading="isSubmitting" :disabled="!isFormValid2 || isSubmitting" > Salvar </ConfirmButton>
              </span>
            </template>
            <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <!-- Carregar documentos da empresa -->
      <p></p>
      <h2 class="blod_color">Documentos do fornecedor</h2> <p></p>
      <h3 class="blod_color">Carregar documentos: </h3>
      <p></p>
      <v-row dense class="my-3">
      <v-col md="3">
        <v-select outlined dense v-model="selectedDocumentType" :items="documentTypes" item-text="nome" item-value="id" >
          <template v-slot:label>
            <span>Tipo de documento <span style="color: red;">*</span></span>
          </template>
        </v-select>
      </v-col>
      <v-col md="6">
        <v-file-input v-model="selectedFile" counter show-size persistent-hint dense outlined @change="checkFileSize" ref="fileInput" >
          <template v-slot:label>
            <span>Anexar documento <span style="color: red;">*</span></span>
          </template>
        </v-file-input>
      </v-col>
      <v-col md="3">
        <v-tooltip top :disabled="!!(selectedDocumentType && selectedFile)">
          <template v-slot:activator="{ on, attrs }">
            <span v-on="on" v-bind="attrs">
              <v-btn @click="uploadDocument" :color="isFormValid ? 'success' : 'grey'" :loading="isUploading" :disabled="!isFormValid || isUploading">
                <v-icon left>mdi-upload</v-icon>
                Enviar documento
              </v-btn>
            </span>
          </template>
          <span>Escolha o "Tipo de documento" e carregue o arquivo para habilitar este botão.</span>
        </v-tooltip> 
      </v-col>
    </v-row>
      <!-- Parte inferior: listar e baixar documentos -->
      <h2 class="blod_color mt-5 mb-5">Documentos carregados:</h2>
        <v-list dense>
          <v-progress-linear v-if="isUploading" loading-text="Carregando Informações...." :indeterminate="true" color="blue"></v-progress-linear>
          <v-list-item-group v-for="(doc, index) in uploadedDocuments" :key="index">
            <v-list-item class="my-2">
              <v-list-item-content>
                <v-list-item-title class="mb-2"> 
                  <span class="font-weight-bold text-primary blod_color ">{{doc.tipodoc.nome}}</span> 
                  <br>
                  <span>{{ extractFileName(doc.arquivo) }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  Carregado em: {{ formatDate(doc.data_carregado) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn small color="green" class="white--text" :href="doc.arquivo" target="_blank">
                  <v-icon left>mdi-download</v-icon>
                  Baixar
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
      </v-list>
    </v-form>
  </div>
</template>

<script>
import Input from '../../Input.vue';
import UtilsFunc from '../../../service/utilsFunc';
import api from '../../../http';
import ConfirmButton from '../../ConfirmButton.vue';
import axios from "axios"
import '../../../assets/css/styles.css';

const { withCRUDUtils } = UtilsFunc

function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj === '') return false;
    if (cnpj.length !== 14) return false;

    // Elimina CNPJs inválidos conhecidos
    if (
        cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999'
    ) {
        return false;
    }

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) return false;

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) return false;

    return true;
}


export default {
  name: "BaseForm",
  props: {
    currentItem: Object,
    loading: Boolean,
    onSubmit: Function
  },
  data: () => withCRUDUtils({
    ufs: [],
    tipos:[],
    locales: [],
    requiredField: [UtilsFunc.isEmptyField('Obrigatório')],
    filesToUpload: {},
    documentTypes: [], 
    selectedDocumentType: null,
    uploadedDocuments: [],
    selectedFile: null,
    isUploading: false,
    isActives: true,
    isSubmitting: false,
    isCNPJValid: null,
  }),
  components: { Input, ConfirmButton },
  methods: {
    async onLocalSubmit() {
      const isValidForm = await this.$refs.form.validate();
      if (!isValidForm) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }

      const cnpjNumber = this.currentItem.cnpj.replace(/[^\d]/g, '');
      if (!validarCNPJ(cnpjNumber)) {
        this.$toast.error("CNPJ inválido.");
        return;
      }

      const criticidadeValue = this.currentItem.criticidade === 'Sim';
      const formatedFields = { ...this.currentItem };
      if (this.currentItem.estado) {
        formatedFields.estado = this.currentItem.estado.sigla;
      }
      if (this.currentItem.municipio) {
        formatedFields.municipio = this.currentItem.municipio.localidade;
      }
      const fields = { 
        ...formatedFields,
        cnpj: cnpjNumber,
        tipos: this.currentItem.tipos.map(tipo => tipo.id),
        criticidade: criticidadeValue,
      };
      this.isSubmitting = true;

      try {

        await this.onSubmit(fields);
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Erro: ${error}`);
      } finally {
          this.isSubmitting = false;
      }
    },

    async getTipos() {
      try {
        const { data } = await api.get("/uteis/tiposfornecedores/");
        this.tipos = data.filter(item => item.ativo == true);
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      }
    },
    async getLocales(uf) {
      const LOADING_NAME = "get:locales";
      this.setLoading(LOADING_NAME);
      const { data } = await api.get(`/uteis/localidades/?uf=${uf.sigla}`);
      this.setLoading(LOADING_NAME, true);
      this.locales = data;
    },
    async getUFS() {
      const LOADING_NAME = "get:ufs";
      this.setLoading(LOADING_NAME);
      const { data } = await api.get(`uteis/uf/`);
      this.ufs = data;
      this.setLoading(LOADING_NAME, true);
    },
    resetFields() {
      this.currentItem.bairro = null
      this.currentItem.estado = null
      this.currentItem.municipio = null
      this.currentItem.logradouro = null
      this.currentItem.complemento = null
    },
    async autofillAddressFromCep(cep) {
      try {
        this.resetFields()
        const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        const uf = this.ufs.find(({ sigla }) => sigla === data.uf);
        await this.getLocales(uf);
        const localidade = this.locales.find(({ localidade }) => localidade === data.localidade);
        this.currentItem.bairro = data.bairro
        this.currentItem.estado = uf
        this.currentItem.municipio = localidade
        this.currentItem.logradouro = data.logradouro
        this.currentItem.complemento = data.complemento
      }
      catch (error) {
        this.resetFields()
        this.$toast.error("CEP não encontrado");
      }
    },
    //Para pegar os nomes dos documentos ativos 
    async getDocumentTypes() {
      const LOADING_NAME = "get:documentTypes";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/uteis/tiposdocs/");
        this.documentTypes = data.filter(doctype => doctype.ativo); 
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    // Metodo para fazer o uploading do documento
    async uploadDocument() {
      if (!this.selectedDocumentType || !this.selectedFile) {
          this.$toast.error("Por favor, selecione o tipo de documento e o arquivo.");
          return;
      }

      this.isUploading = true;

      let formData = new FormData();
      formData.append("arquivo", this.selectedFile);
      formData.append("tipodoc", this.selectedDocumentType);
      formData.append("ativo", this.isActives);
      formData.append("fornecedor", this.currentItem.id);
      const currentDate = new Date().toISOString().split('T')[0];
      formData.append("data_carregado", currentDate);
      this.ativo = true

      try {
          await api.post("/fornecedores/docs/", formData);
          this.$toast.success("Documento carregado com sucesso!");
          this.selectedFile = null; 
          this.selectedDocumentType = null;
          this.fetchUploadedDocuments();
          
      } catch (error) {

          this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      } finally {
          this.isUploading = false;
      }
    },
  // Pegar os documentos do back
    async fetchUploadedDocuments() {
      this.isUploading = true;
      try {
        const { data } = await api.get(`/fornecedores/docs/?fornecedor=${this.currentItem.id}`);
        this.uploadedDocuments = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado ao buscar os documentos. Erro: ${error}`);
      } finally {
          this.isUploading = false;
      } 
    },
    // Pega os arquivos e mostra mna parte inferior da tela os arquivos
    async loadUploadedDocuments() {
      try {
        const { data } = await api.get(`/fornecedores/docs/?fornecedor=${this.currentItem.id}`);
        this.uploadedDocuments = data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      } catch (error) {
        this.$toast.error(`Erro ao carregar documentos: ${error}`);
      }
    },
    // Retira o caminho do nome do arquivo 
    extractFileName(url) {
      if (url) {
        return url.split('/').pop();
      }
      return "";
    },
    // Verifica o tamnaho do doc se ultrapasa 1MB 
    checkFileSize() {
      if (this.selectedFile && this.selectedFile.size > 1000000) { // maior que 1MB
        this.$toast.error("O tamanho do arquivo é maior do que o permitido (1MB).");
        this.selectedFile = null; // remova a referência do arquivo para que o usuário não possa fazer o upload
        this.$refs.fileInput.reset();
      }
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
    validateCNPJ() {
      const cnpjNumber = this.currentItem.cnpj.replace(/[^\d]/g, '');
      this.isCNPJValid = validarCNPJ(cnpjNumber);
    },
    formatDate(dateString) {
      if (!dateString) return '';
      const [year, month, day] = dateString.split('-');
      return `${day}/${month}/${year}`;
    },
  },
  created() {
   this.getDocumentTypes();
  },
  async mounted() {
    this.fetchUploadedDocuments();
    this.currentItem.ativo = Number(this.currentItem.ativo);
    await this.getTipos();
      if (this.currentItem.tipo && this.tipos.length) {
        const tiposSelecionados = this.tipos.filter(tipo => this.currentItem.tipo.includes(tipo.nome));
        this.currentItem.tipos = tiposSelecionados;
      }

    if (this.currentItem.criticidade) {
        this.currentItem.criticidade = 'Sim'; // Defina o valor padrão como 'Sim' ou 'Não', conforme necessário
      }  else {this.currentItem.criticidade = 'Não'}
    await this.getUFS();
      if (this.currentItem.estado) {
        const uf = { sigla: this.currentItem.estado }
        await this.getLocales(uf);
      }
  },
  computed: {
    isFormValid() {
      return this.selectedDocumentType && this.selectedFile;
    },
    isFormValid2() {
      return !!( this.currentItem.nome_fantasia && 
        this.currentItem.razao_social && 
        this.currentItem.cnpj && 
        this.currentItem.criticidade &&
        this.currentItem.tipo.length > 0); 
    },
  },
}
</script>

<style scoped>
.main-fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  column-gap: 1rem;
}
</style>
