<template>
  <div>
    <v-form @submit.prevent="onLocalSubmit" ref="form" dense>
      <!-- <pre>{{currentItem}}</pre> -->
      <v-row class="mt-2" dense>
        <v-col>
          <h2 class="blod_color mt-2" dense>Dados Gerais: </h2>
          <p></p>
          <v-row dense>
            <v-col class="main-fields">
              <v-text-field dense outlined v-model="currentItem.nome_fantasia" clearable :rules="requiredField">
                <template v-slot:label>
                  <span>Nome fantasia <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
              <v-text-field dense outlined v-model="currentItem.razao_social" clearable :rules="requiredField">
                <template v-slot:label>
                  <span>Razão social <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
              <v-text-field dense outlined v-model="currentItem.cnpj" v-mask="'##.###.###/####-##'"  :rules="requiredField" :error-messages="isCNPJValid === false ? ['CNPJ inválido'] : []" @input="validateCNPJ">
                <template v-slot:label>
                  <span>CNPJ <span style="color: red;">*</span></span>
                </template>
              </v-text-field>
              <v-text-field dense outlined v-model="currentItem.tel" label="Telefone Prinpal" v-mask="'## ##### - ####'" clearable  />
              <v-text-field dense outlined v-model="currentItem.email" label="E-mail Prinpal"  clearable />
              <v-text-field dense outlined v-model="currentItem.site" label="Site" />
              <v-autocomplete dense outlined v-model="currentItem.tipos" :items="tipos"
                item-value="id" item-text="nome" multiple return-object clearable >
                 <template v-slot:label>
                  <span>Tipo de Fornecedor<span style="color: red;">*</span></span>
                 </template>
              </v-autocomplete>
              <v-select dense outlined v-model="currentItem.criticidade" clearable :rules="requiredField" :items="['Sim', 'Não']">
                 <template v-slot:label>
                  <span>Crítico<span style="color: red;">*</span></span>
                 </template>
              </v-select>
              <v-text-field dense outlined v-model="currentItem.data_contrato" label="Data do Contrato"  clearable  type="date"/>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col>
              <v-textarea v-model="currentItem.obs" label="Observação" outlined clearable rows="3" />
            </v-col>
          </v-row>
          <h2 class="blod_color">Endereço: </h2>
          <p></p>
          <v-row dense>
            <v-col class="main-fields">
              <Input label="CEP" @change="autofillAddressFromCep" v-mask="'#####-###'" v-model="currentItem.cep"
                clearable />
              <Input label="Logradouro" v-model="currentItem.logradouro" clearable/>
              <Input label="Número" v-model="currentItem.numero" clearable />
              <v-autocomplete dense label="Estado" v-model="currentItem.estado" outlined :loading="isLoading('get:ufs')"
                :items="ufs" item-value="sigla" item-text="sigla" return-object @change="getLocales" clearable />
              <v-autocomplete dense label="Município" v-model="currentItem.municipio" outlined
                :loading="isLoading('get:locales')" :items="locales" item-value="localidade" item-text="localidade"
                return-object clearable />
              <Input label="Bairro" v-model="currentItem.bairro" clearable />
              <Input label="Complemento" v-model="currentItem.complemento" clearable />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col class="text-end">
          <v-tooltip top :disabled="isFormValid2">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <ConfirmButton :color="isFormValid2 ? 'success' : 'grey'" :loading="isSubmitting" :disabled="!isFormValid2 || isSubmitting" > Salvar </ConfirmButton>
              </span>
            </template>
            <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import Input from '../../Input.vue';
import UtilsFunc from '../../../service/utilsFunc';
import api from '../../../http';
import ConfirmButton from '../../ConfirmButton.vue';
import axios from "axios"
import '../../../assets/css/styles.css';

const { withCRUDUtils } = UtilsFunc

function validarCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');
    if (cnpj === '') return false;
    if (cnpj.length !== 14) return false;

    // Elimina CNPJs inválidos conhecidos
    if (
        cnpj === '00000000000000' ||
        cnpj === '11111111111111' ||
        cnpj === '22222222222222' ||
        cnpj === '33333333333333' ||
        cnpj === '44444444444444' ||
        cnpj === '55555555555555' ||
        cnpj === '66666666666666' ||
        cnpj === '77777777777777' ||
        cnpj === '88888888888888' ||
        cnpj === '99999999999999'
    ) {
        return false;
    }

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) return false;

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) return false;

    return true;
}

export default {
  name: "BaseForm",
  props: {
    currentItem: Object,
    loading: Boolean,
    onSubmit: Function
  },
  data: () => withCRUDUtils({
    ufs: [],
    tipos:[],
    locales: [],
    requiredField: [UtilsFunc.isEmptyField('Obrigatório')],
    isUploading: false,
    isActives: true,
    isSubmitting: false,
    isCNPJValid: null,
  }),
  components: { Input, ConfirmButton },
  methods: {
    async onLocalSubmit() {
      const isValidForm = await this.$refs.form.validate();
      if (!isValidForm) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }

      const cnpjNumber = this.currentItem.cnpj.replace(/[^\d]/g, '');
      if (!validarCNPJ(cnpjNumber)) {
        this.$toast.error("CNPJ inválido.");
        return;
      }

      const criticidadeValue = this.currentItem.criticidade === 'Sim';
      const formatedFields = { ...this.currentItem };
      if (this.currentItem.estado) {
        formatedFields.estado = this.currentItem.estado.sigla;
      }
      if (this.currentItem.municipio) {
        formatedFields.municipio = this.currentItem.municipio.localidade;
      }
      const fields = { 
        ...formatedFields,
        cnpj: cnpjNumber,
        tipos: this.currentItem.tipos.map(tipo => tipo.id),
        criticidade: criticidadeValue,
      };
      this.isSubmitting = true;

      try {

        await this.onSubmit(fields);
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado. Erro: ${error}`);
      } finally {
          this.isSubmitting = false;
      }
    },
    async getTipos() {
      try {
        const { data } = await api.get("/uteis/tiposfornecedores/");
        this.tipos = data.filter(item => item.ativo == true);
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe. Apresentou algum erro. ${error}`);
      }
    },
    async getLocales(uf) {
      const LOADING_NAME = "get:locales";
      this.setLoading(LOADING_NAME);
      const { data } = await api.get(`/uteis/localidades/?uf=${uf.sigla}`);
      this.setLoading(LOADING_NAME, true);
      this.locales = data;
    },
    async getUFS() {
      const LOADING_NAME = "get:ufs";
      this.setLoading(LOADING_NAME);
      const { data } = await api.get(`uteis/uf/`);
      this.ufs = data;
      this.setLoading(LOADING_NAME, true);
    },
    resetFields() {
      this.currentItem.bairro = null
      this.currentItem.estado = null
      this.currentItem.municipio = null
      this.currentItem.logradouro = null
      this.currentItem.complemento = null
    },
    async autofillAddressFromCep(cep) {
      try {
        this.resetFields()
        const { data } = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        const uf = this.ufs.find(({ sigla }) => sigla === data.uf);
        await this.getLocales(uf);
        const localidade = this.locales.find(({ localidade }) => localidade === data.localidade);
        this.currentItem.bairro = data.bairro
        this.currentItem.estado = uf
        this.currentItem.municipio = localidade
        this.currentItem.logradouro = data.logradouro
        this.currentItem.complemento = data.complemento
      }
      catch (error) {
        this.resetFields()
        this.$toast.error("CEP não encontrado");
      }
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
    validateCNPJ() {
      const cnpjNumber = this.currentItem.cnpj.replace(/[^\d]/g, '');
      this.isCNPJValid = validarCNPJ(cnpjNumber);
    },
  },
async mounted() {
  this.currentItem.ativo = Number(this.currentItem.ativo);
  await this.getTipos();
  if (this.currentItem.tipo && this.tipos.length) {
    const tiposSelecionados = this.tipos.filter(tipo => this.currentItem.tipo.includes(tipo.nome));
    this.currentItem.tipos = tiposSelecionados;
  }
  await this.getUFS();
      if (this.currentItem.estado) {
        const uf = { sigla: this.currentItem.estado }
        await this.getLocales(uf);
      }
},
computed: {
    isFormValid2() {
      return !!( this.currentItem.nome_fantasia && 
        this.currentItem.razao_social && 
        this.currentItem.cnpj && 
        this.currentItem.criticidade && 
        this.currentItem.tipos ); 
    },
  },
}
</script>

<style scoped>
.main-fields {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  column-gap: 1rem;
}
</style>
