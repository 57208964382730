<template>
  <div>
    <!-- <pre>{{currentItem}}</pre> -->
    <v-data-table :loading="isLoading('get:equipamentoFornecedor')" :items="equipamentoFornecedor" :search="search" :headers="headers" 
      v-model="selectedRows" show-select>
      <template v-slot:top>
        <v-row class="mt-4" dense>
          <v-col cols="5">
            <v-text-field dense outlined v-model="search" label="Pesquisar" class="mx-0" append-icon="mdi-magnify" clearable />
          </v-col>
          <v-col class="text-end">
            <v-btn class="mr-4 elevation-0" text @click="atualizar">
              <v-icon left> mdi-reload </v-icon>
              atualizar
            </v-btn>
            <v-btn color="info" class="elevation-0 mr-4"  @click="dialog.duplicate = true" :disabled="selectedRows.length < 1" >
              <v-icon left>  mdi-content-copy </v-icon>
              Reajuste
            </v-btn>
            <v-btn color="success" class="elevation-0" @click="dialog.create = true">
              <v-icon left>  mdi-plus </v-icon>
              Novo
            </v-btn>
          </v-col> 
        </v-row>
      </template>
      <!-- <template v-slot:[`item.nomeEquipamento`]="{ item }">
        {{ getNomeEquipamentoById(item.nomeEquipamento) }}
      </template> -->
      <template v-slot:[`item.valordiario`]="{ item }">
        <template v-if="item.valordiario !== null">
          {{ item.valordiario | formatReal }}
        </template>
        <template v-else>
          {{ '' }}
        </template>
      </template>
      <template v-slot:[`item.valormensal`]="{ item }">
        <template v-if="item.valormensal !== null">
          {{ item.valormensal | formatReal }}
        </template>
        <template v-else>
          {{ '' }}
        </template>
      </template>
      <template v-slot:[`item.inicioVigencia`]="{ item }">
        <span>{{ formatDate(item.inicioVigencia) }}</span>
      </template>
      <template v-slot:[`item.fimVigencia`]="{ item }">
        <span>{{ formatDate(item.fimVigencia) }}</span>
      </template>
      <template v-slot:[`item.ativo`]="{ item }">
        <v-chip class="ma-2" :color="item.ativo ? 'blue' : 'grey'">
          <span class="white--text">{{ item.ativo ? 'Ativo' : 'Inativo' }}</span>
        </v-chip>
      </template>
      <template v-slot:[`item.editar`]="{ item }">
        <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @click="onSelectRow(item, 'update')">
                    <v-icon color="green">mdi-pencil</v-icon>
                </v-btn>
            </template>
            <span>Clique para editar</span>
        </v-tooltip>
      </template>
      <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-simple-checkbox :value="isSelected" @click="() => select(!isSelected)" v-on="on" v-bind="attrs">
              {{ isSelected }}
            </v-simple-checkbox>
          </template>
          <span>Selecione essa linha para REAJUSTAR</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-row>
<!-- Editar Items -->
      <v-dialog v-model="dialog.update" v-if="dialog.update" width="800px">
        <v-card >
          <v-card-title class="blod_color"> 
            Editar Equipamento: {{ (selectedRow.nomeEquipamento.nome) }} / ID: {{ selectedRow.id }}
            <v-spacer></v-spacer>
            <v-btn icon @click="closeUpdateDialog">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <EditarItemfornecedor :currentItem="selectedRow" :loading="isLoading('put:itens')" :onSubmit="updateItens" />
        </v-card>
      </v-dialog>
<!-- Novo Item -->
      <v-dialog v-model="dialog.create" width="800px" >
        <v-card >
          <v-card-title class="blod_color"> Cadastrar Novo Equipamento
            <v-spacer></v-spacer>
            <v-btn icon @click="closeCreateDialog">
              <v-icon>
                mdi-close
              </v-icon>
            </v-btn>
          </v-card-title>
          <NovoEquipamento :currentItem="newItem" :idFornecedor="currentItem.id" :loading="isLoading('post:itemfornecedor')" :onSubmit="postNovoItem" class="ml-5 mr-5" />
        </v-card>
      </v-dialog>
    </v-row>
    <!-- Reajuste -->
    <v-dialog v-if="dialog.duplicate" v-model="dialog.duplicate" max-width="900">
      <v-card>
        <v-card-title class="blod_color">
          Reajustar equipamentos
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.duplicate = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="formRef">
            <v-row dense>
              <v-col cols="5">
                <v-text-field type="date" outlined dense v-model="inicioVigencia" :rules="requiredField"
                  @change="forceRevalidate">
                  <template v-slot:label>
                    <span>Início da vigência <span style="color: red;">*</span></span>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field hide-details outlined dense v-model.lazy="duplicatePercent" v-money="currencyMask">
                  <template v-slot:label>
                    <span>Percentual do Reajuste <span style="color: red;">*</span></span>
                  </template>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="text-start">
                <v-btn-toggle v-model="cloneEquipamento" mandatory class="white--text">
                  <v-btn :color="!cloneEquipamento ? 'error' : ''">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">Inativo</span>
                      </template>
                      <span>Deixe INATIVO se HOJE não for a data de início do reajuste.</span>
                    </v-tooltip>
                  </v-btn>
                  <v-btn :color="cloneEquipamento ? 'green' : ''">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <span v-on="on">Ativo</span>
                      </template>
                      <span>Deixe ATIVO se HOJE for a data de início do reajuste.</span>
                    </v-tooltip>
                  </v-btn>
                </v-btn-toggle>
              </v-col>
              <v-col class="text-end">
                <v-tooltip top :disabled="isFormValid">
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs">
                      <ConfirmButton :onConfirm="duplicateEquipamento" :color="isFormValid ? 'success' : 'grey'"
                        :loading="isSubmitting" :disabled="!isFormValid || isSubmitting">Salvar</ConfirmButton>
                    </span>
                  </template>
                  <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
                </v-tooltip>
              </v-col>
            </v-row>
            <v-data-table :headers="headersDuplicate" :items="selectedRows" hide-default-footer>
              <template v-slot:[`item.valordiario`]="{ item }">
                {{ formatCurrency(item.valordiario) }}
              </template>
              <template v-slot:[`item.valormensal`]="{ item }">
                {{ formatCurrency(item.valormensal) }}
              </template>
              <template v-slot:[`item.price_after`]="{ item }">
                {{ formatCurrency(Number(item.valordiario) + ((item.valordiario * duplicatePercentNumber) / 100)) }}
              </template>
              <template v-slot:[`item.price_aftermensal`]="{ item }">
                {{ formatCurrency(Number(item.valormensal) + ((item.valormensal * duplicatePercentNumber) / 100)) }}
              </template>
              <!-- <template v-slot:[`item.nomeEquipamento`]="{ item }">
                {{ getNomeEquipamentoById(item.nomeEquipamento) }}
              </template> -->
            </v-data-table>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import UtilsFunc from '../../../../service/utilsFunc';
import EditarItemfornecedor from './EditarItensFornecedor.vue';
import NovoEquipamento from './NovoItensFornecedor.vue';
import ConfirmButton from '../../../ConfirmButton.vue';
import '../../../../assets/css/styles.css';

import api from '../../../../http';

const { withCRUDUtils } = UtilsFunc

export default {
  name: "ItensDoForncedor",
  props: {
    currentItem: Object,
    loading: Boolean,
    onSubmit: Function
  },
  components: { EditarItemfornecedor, NovoEquipamento, ConfirmButton },
  data: () => withCRUDUtils({
    newItem: { ativo: 1 },
    equipamentoFornecedor: [],
    nomeequipamentos: [],
    fornecedor: [],
    isLoadingEquipamentos: false,
    isLoadingFornecedor: false,
    selectedRows: [],
    dialog: {
      duplicate: false, 
    },
    inicioVigencia: "", 
    duplicatePercent: "", 
    cloneEquipamento: 0, 
    isSubmitting: false,
    requiredField: [e => e !== undefined || 'Obrigatório'],
    currencyMask: {
      decimal: ',',
      thousands: '.',
      suffix: ' %',
      precision: 2,
    },
    headers: [
        { text: "Editar item", sortable: true, align: "center", value: "editar" },
        { text: "Id", value: "id", width: 90 },
        { text: "Equipamento", value: "nomeEquipamento.nome" },
        { text: "Valor Diário", value: "valordiario" },
        { text: "Valor Mensal", value: "valormensal" },
        { text: "Início Vigência", value: "inicioVigencia" },
        { text: "Fim Vigência", value: "fimVigencia" },
        { text: "Status", value: "ativo" },
      ],
       headersDuplicate: [
      { text: "Equipamento", value: "nomeEquipamento.nome", width: 250 },
      { text: "Preço diário pré reajuste", value: "valordiario" },
      { text: "Preço mensal pré reajuste", value: "valormensal" },
      { text: "Preço diário pós reajuste", value: "price_after" },
      { text: "Preço mensal pós reajuste", value: "price_aftermensal" },
    ],
  }),
  methods: {
    async updateItens(fields) {
      const LOADING_NAME = "put:itens";
      this.setLoading(LOADING_NAME);
      try {
        await api.put(`/equipamentos/equipamentos_fornecedor/${fields.id}/`, fields);
        await this.getEquipamentoForncedor()
        this.dialog.update = false
        this.$toast.success('Item atualizado com sucesso!')
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado (pag.ItensFornecedor / updateItens). Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async postNovoItem(fields) {
      const LOADING_NAME = "post:itemfornecedor";
      this.setLoading(LOADING_NAME);
      try {
        await api.post(`/equipamentos/equipamentos_fornecedore/`, {
      ...fields,
        fornecedor: this.currentItem.id, 
      });
        await this.getEquipamentoForncedor();
        this.dialog.create = false;
        this.$toast.success('Item criado com sucesso!');
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado (pag.ItensFornecedor / postNovoItem). Deu erro ${error}`)
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async onLocalSubmit() {
      const isValidForm = await this.$refs.form.validate();
      if (!isValidForm) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
      const fields = { 
      };
      this.isSubmitting = true;
      try {
        await this.onSubmit(fields);
      } catch (error) {
        this.$errorHandler(error);
        this.$toast.error(`Desculpe! Aconteceu algo errado (pag.ItensFornecedor / onLocalSubmit). Erro: ${error}`);
      } finally {
          this.isSubmitting = false;
      }
    },
    async getEquipamentoForncedor() {
      const LOADING_NAME = "get:equipamentoFornecedor";
      this.setLoading(LOADING_NAME);
      const idForncedeor = this.currentItem.id
      // console.log(idForncedeor)
      try {
        const { data } = await api.get(`/equipamentos/equipamentos_fornecedores/`);
        this.equipamentoFornecedor = data
          .filter(item => item.fornecedor === idForncedeor)
          .sort((a, b) => b.ativo - a.ativo);
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (pag.ItensFornecedor / getEquipamentoForncedor). ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    }, 
    async getNomeEquipamento() {
      this.isLoadingEquipamentos = true;
      try {
        const { data } = await api.get(`equipamentos/nomeequipamentos/`);
        this.nomeequipamentos = data.filter(item => item.ativo)
        // console.log('NomeEquipamento', this.nomeequipamentos)
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (pag.ItensFornecedor / getNomeEquipamento). ${error}`);
      } finally {
        this.isLoadingEquipamentos = false;
      }
    },
    async getFornecedor() {
      this.isLoadingFornecedor = true;
      try {
        const { data } = await api('fornecedores/fornecedores/')
        data.sort((a, b) => a.nome_fantasia.localeCompare(b.nome_fantasia));
        this.fornecedor = data.filter( item => item.ativo) 
        // console.log('Fornecedor --< ',this.fornecedor)
      } catch (error) {
        this.$toast.error(`Desculpe. Apresentou algum erro (pag.ItensFornecedor / getFornecedor). ${error}`);
      } finally {
        this.isLoadingFornecedor = false;
      }
    },
    async duplicateEquipamento() {
      const isValid = this.$refs.formRef.validate();
      if (!isValid) {
        this.$toast.error("Por favor, preencha todos os campos obrigatórios.");
        return;
      }
      
      const proceduresToDuplicate = this.selectedRows.map(row => {
        const priceAfterReajuste = (Number(row.valordiario) + (row.valordiario * this.duplicatePercentNumber) / 100).toFixed(2);
        const priceAfterReajusteMensal = (Number(row.valormensal) + (row.valormensal * this.duplicatePercentNumber) / 100).toFixed(2);
        const EquipamentoID = row.nomeEquipamento.id
        return api.post('/equipamentos/equipamentos_fornecedore/', 
          { ...(row), 
            nomeEquipamento: EquipamentoID,
            valordiario: priceAfterReajuste, 
            valormensal: priceAfterReajusteMensal, 
            inicioVigencia: this.inicioVigencia, 
            ativo: Boolean(this.cloneEquipamento) });
      });
      await Promise.all(proceduresToDuplicate)
      await this.getEquipamentoForncedor()
      this.$toast.success('OK')
      this.selectedRows = []
      this.inicioVigencia = ""
      this.dialog.duplicate = false
    },
    // getNomeEquipamentoById(id) {
    //   const equipamento = this.nomeequipamentos.find(equip => equip.id === id);
    //   return equipamento ? equipamento.nome : 'Equipamento não encontrado';
    // },
    formatDate(isoString) {
      if (!isoString) return '';
      const date = new Date(isoString);
      date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); 
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); 
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    },
    formatCurrency(value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value);
    },
    closeUpdateDialog() {
      this.dialog.update = false;
    },
    closeCreateDialog() {
      this.dialog.create = false;
    },
    atualizar(){
      this.search = '';
      this.getEquipamentoForncedor()
    },
    forceRevalidate() {
      this.$nextTick(() => {
        if (this.$refs.formRef) {
          this.$refs.formRef.validate();
        }
      });
    },
    validateForm() {
      if (this.$refs.formRef) {
        this.$refs.formRef.validate();
        this.$emit('form-validated');
      }
    },
  },
  computed: {
    duplicatePercentNumber() {
      if (typeof this.duplicatePercent === 'string') {
        return Number(this.duplicatePercent.replace(/%|\.|\s/g, '').replace(',', '.'));
      }
      return 0;
    },
    formattedData() {
      const formatDate = Intl.DateTimeFormat('pt-br', { dateStyle: 'short' }).format
      return this.tableData.map(row => ({ ...row, formatedCreatedAt: formatDate(new Date(row.created_at)) }))
    },
    isFormValid() {
      return !!(this.duplicatePercentNumber > 0 && this.inicioVigencia);
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },  

  },
  mounted() {
    this.getEquipamentoForncedor();
    this.getNomeEquipamento();
    this.getFornecedor()
  },
   watch: {
    'selectedRows.fimVigencia'() {
      this.$refs.formRef.validate(); // Revalida o formulário
    },
    'inicioVigencia'(newValue) {
      const today = new Date().toISOString().split('T')[0];
      this.cloneEquipamento = newValue === today ? 1 : 0;
    },
  },
}
</script>

<style>
.modal-content {
  padding-bottom: 20px;
}

.v-data-table {
  margin-bottom: 20px;
}
</style>
